import {
    DELETE_PROCESS_EXECUTION,
    FETCH_ALL_EXECUTIONS, FETCH_ALL_EXECUTIONS_COUNT,
    FETCH_EXECUTION_TIME_ESTIMATION,
    FETCH_PROCESS_EXECUTION,
    FETCH_PROCESS_EXECUTION_EXCEPTION_REASON,
    FETCH_PROCESS_EXECUTION_EXCEPTION_TYPE,
    FETCH_PROCESS_EXECUTION_EXCEPTIONS, FETCH_PROCESS_EXECUTION_EXCEPTIONS_COUNT,
    FETCH_STATUS_PROCESS, REVOKE_PRIORITY_FROM_EXECUTION
} from "../../constants";
import { URL_PROCESS_EXECUTION, URL_SR_QUEUES_MNGT } from "../../constants/endpoints";
import { formatDateParam, formatDateTimeIsoParam } from "util";

export const fetchProcessExecutionExceptions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    divisions,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    searchText,
    tags = []
) => ({
    type: FETCH_PROCESS_EXECUTION_EXCEPTIONS,
    request: {
        url: encodeURI(`${URL_PROCESS_EXECUTION}exceptions${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            page,
            size,
            sortField,
            sortOrder,
            processes: `${processes}`,
            exceptions: `${exceptions}`,
            exceptionsType: `${exceptionsType}`,
            fleetIds: `${fleetIds?.join(",")}`,
            hidden: `${showHidden}`,
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            divisionsIds: divisions?.map(({ id }) => id).join(),
            tagsIds: tags?.join()
        },
    },
});

export const fetchProcessExecutionExceptionsCount = (
    processes,
    divisions,
    exceptions,
    exceptionsType,
    fleetIds,
    executionStartTime,
    executionEndTime,
    showHidden = false,
    searchText,
    tags = []
) => ({
    type: FETCH_PROCESS_EXECUTION_EXCEPTIONS_COUNT,
    request: {
        url: encodeURI(`${URL_PROCESS_EXECUTION}exceptions/count${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            processes: `${processes}`,
            exceptions: `${exceptions}`,
            exceptionsType: `${exceptionsType}`,
            fleetIds: `${fleetIds?.join(",")}`,
            hidden: `${showHidden}`,
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            divisionsIds: divisions?.map(({ id }) => id).join(),
            tagsIds: tags?.join()
        },
    },
});
export const fetchExecutionsCount = (
    processes,
    status,
    launchingDatetime,
    endDatetime,
    searchText,
    resources,
    triggers,
    tags,
    fleets
) => ({
    type: FETCH_ALL_EXECUTIONS_COUNT,
    request: {
        url: `${URL_PROCESS_EXECUTION}count`,
        params: {
            processes: `${processes}`,
            status: `${status}`,
            triggers: `${triggers}`,
            resources: `${resources}`,
            launchingDatetime: formatDateParam(launchingDatetime),
            endDatetime: formatDateParam(endDatetime),
            searchText,
            tagsIds: tags?.join(),
            fleetIds: fleets.join(),
        },
    },

});

export const fetchExecutionTimeEstimation = (processId, minusDays) => ({
    type: FETCH_EXECUTION_TIME_ESTIMATION,
    request: {
        url: `${URL_PROCESS_EXECUTION}${processId}/estimate`,
        method: "get",
        params: { minusDays },
    },
});
export const fetchAllStatus = (processesIds = []) => ({
    type: `${FETCH_STATUS_PROCESS}`,
    request: {
        url: `${URL_PROCESS_EXECUTION}allstatus`,
        params: { processesIds: processesIds.join() }
    },
});

export const fetchProcessExecutionByProcess = (
    id,
    page,
    size,
    sortField,
    sortOrder,
    status,
) => ({
    type: `LIST_${FETCH_PROCESS_EXECUTION}`,
    request: {
        url: `${URL_PROCESS_EXECUTION}${id}`,
        params: {
            page,
            size,
            sortField,
            sortOrder,
            status: `${status}`,
        }
    },
});
export const getStatusByProcess = (id) => ({
    type: `${FETCH_STATUS_PROCESS}`,
    request: { url: `${URL_PROCESS_EXECUTION}status/${id}` },
    meta: {
        requestKey: `${id}`
    }
});
export const showProcessExecutionExceptions = (exceptions) => ({
    type: DELETE_PROCESS_EXECUTION,
    request: {
        url: `${URL_PROCESS_EXECUTION}show/?exceptions=${exceptions}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const deleteProcessExecutionExceptions = (exceptions) => ({
    type: DELETE_PROCESS_EXECUTION,
    request: {
        url: `${URL_PROCESS_EXECUTION}hide/?exceptions=${exceptions}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const deleteProcessExecutionException = (id) => ({
    type: DELETE_PROCESS_EXECUTION,
    request: {
        url: `${URL_PROCESS_EXECUTION}${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => response,
    },
});
export const fetchExecutions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    status,
    launchingDatetime,
    endDatetime,
    searchText,
    resources,
    triggers,
    tags,
    fleetIds = []
) => ({
    type: FETCH_ALL_EXECUTIONS,
    request: {
        url: encodeURI(`${URL_PROCESS_EXECUTION}${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            page,
            size,
            sortField,
            sortOrder,
            processes: `${processes}`,
            status: `${status}`,
            triggers: `${triggers}`,
            resources: `${resources}`,
            launchingDatetime: formatDateParam(launchingDatetime),
            endDatetime: formatDateParam(endDatetime),
            tagsIds: tags?.join(),
            fleetIds: fleetIds.join()
        },
    },

});
export const fetchProcessExecutionExceptionsType = (
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
) => {
    const ids = Array.isArray(fleetIds) ? fleetIds.join() : fleetIds;
    return {
        type: FETCH_PROCESS_EXECUTION_EXCEPTION_TYPE,
        request: {
            url: `${URL_PROCESS_EXECUTION}exceptionsType?processIds=${processIds?.join()}`,
            params: {
                loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
                completedDatetime: formatDateTimeIsoParam(completedDatetime),
                fleetIds: ids,
            },
        },
    };
};
export const fetchProcessExecutionExceptionsReason = (
    exceptionTypes = "",
    processIds = [],
    loadedDatetime = null,
    completedDatetime = null,
    fleetIds = [],
) => {
    const ids = Array.isArray(fleetIds) ? fleetIds.join() : fleetIds;
    return {
        type: FETCH_PROCESS_EXECUTION_EXCEPTION_REASON,
        request: {
            url: encodeURI(`${URL_PROCESS_EXECUTION}exceptionsReason?types=${exceptionTypes}&processIds=${processIds?.join()}`),
            params: {
                loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
                completedDatetime: formatDateTimeIsoParam(completedDatetime),
                fleetIds: ids,
            },
        },
    };
};

export const revokePriorityFromAnExecution = (executionId, onSuccess, onError) => ({
        type: REVOKE_PRIORITY_FROM_EXECUTION,
        request: {
            url: `${URL_SR_QUEUES_MNGT}/executions/${executionId}/revoke-priority`,
            method: "put"
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (err) => {
                if (onError) onError();
                throw err;
            },
        },
    });
