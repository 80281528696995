import {
  React, useState, useEffect, useMemo
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card, Grid, Paper, Typography, makeStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { isEmpty, isEqual } from "lodash";
import {
 selectItem, selectAll, removeFromAvailable, addToSelected
} from "util/helpers/dragDropSelctors.helpers";
import EmptyDropSection from "../../../components/EmptyDropSection";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import DataNotFound from "../../../components/DataNotFound";
import CustomButton from "components/CustomButton";
import InformationIcon from "components/InformationIcon";
import clsx from "clsx";
import useStyles from "./style.js";

// A little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Returns true if the item is a tag, false if not
 */
const isTag = (item) => Object.keys(item).includes("name");

const grid = 8;

const Pagination = ({ currentPages, setCurrentPages, listkey }) => {
  const { t } = useTranslation();
  const { currentPage, totalPages } = currentPages[listkey] ?? {};
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPages((prevState) => ({ ...prevState, [listkey]: { ...prevState?.[listkey], currentPage: currentPage + 1 } }));
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPages((prevState) => ({ ...prevState, [listkey]: { ...prevState?.[listkey], currentPage: currentPage - 1 } }));
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" pl={1} visibility={totalPages > 0 ? "visible" : "hidden"}>
      <Typography variant="caption" color="primary">
        {t("page.of", {
          page: currentPage,
          pages: totalPages
        })}
      </Typography>
      <Box>
        <IconButton size="small" onClick={handlePrevPage} disabled={!currentPage || currentPage === 1} color="primary">
          <ChevronLeftIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleNextPage} disabled={!currentPage || currentPage === totalPages} color="primary">
          <ChevronRightIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  )
}

const itemStyle = makeStyles((theme) => ({
  // some basic styles to make the items look a bit nicer
  item: (props) => ({
    userSelect: "none",
    padding: `${grid}px ${grid * 2}px`,
    margin: `0 0 ${grid}px 0`,
    boxShadow:
        "0px 1px 2px 0px rgb(172,186,255)",
    fontWeight: 400,
    fontSize: 14,
    borderRadius: "2px",
    background: props.isSelected ? theme.custom.color.containerBackground : theme.palette.background.default,
    borderLeft: "10px solid",
    borderColor: props.itemIsTag ? theme.custom.color.color6 : theme.custom.color.color2,
    display: "flex",
    // styles we need to apply on draggables
    ...props.draggableStyle,
    ...(!props.isDragging && props.draggableStyle.transform ? { transform: "translateY(0) !important" } : {}),
  })
}));

const getItemStyle = (isDragging, draggableStyle, itemIsTag, isSelected = false) => itemStyle({
 isDragging, draggableStyle, itemIsTag, isSelected
}).item;

const ProcessAndTagSelector = ({
  availableProcesses,
  selectedProcesses,
  isDisabled,
  setAvailableProcesses,
  setSelectedProcesses,
  availableTags,
  selectedTags,
  setAvailableTags,
  setSelectedTags,
  onUnAssignProcess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableProcessSearchTerm, setAvailableProcessSearchTerm] = useState("");
  const [availableTagSearchTerm, setAvailableTagSearchTerm] = useState("");
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedProcesses, setCheckedProcesses] = useState([]);
  const [checkedAssignedItems, setCheckedAssignedItems] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);
  const itemsPerPage = 10;
  const [currentPages, setCurrentPages] = useState({
    availableProcesses: {
        currentPage: 1,
        totalPages: Math.ceil(availableProcesses.length / itemsPerPage),
    },
    availableTags: {
        currentPage: 1,
        totalPages: Math.ceil(availableTags.length / itemsPerPage),
    },
    selectedItems: {
        currentPage: 1,
        totalPages: Math.ceil(selectedItems.length / itemsPerPage),
    },
  });
  const indexOfLastItemProcesses = currentPages.availableProcesses.currentPage * itemsPerPage;
  const indexOfFirstItemProcesses = indexOfLastItemProcesses - itemsPerPage;
  const sortProcessesList = (list) => list?.map((item, i) => ({ ...item, i })).filter((item) => (availableProcessSearchTerm.trim() !== "" ? (item?.processName?.toLowerCase()?.includes(availableProcessSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(availableProcessSearchTerm.trim())) : true))
  const PagedAvailableProcesses = useMemo(() => sortProcessesList(availableProcesses)?.slice(indexOfFirstItemProcesses, indexOfLastItemProcesses), [availableProcesses, currentPages?.availableProcesses?.currentPage, availableProcessSearchTerm])
  useEffect(() => {
    setCurrentPages((prevState) => ({ ...prevState, availableProcesses: { ...prevState?.availableProcesses, ...(availableProcessSearchTerm ? { currentPage: 1 } : {}), totalPages: Math.ceil(sortProcessesList(availableProcesses).length / itemsPerPage) } }));
  }, [availableProcesses, availableProcessSearchTerm]);

  const indexOfLastItemTags = currentPages.availableTags.currentPage * itemsPerPage;
  const indexOfFirstItemTags = indexOfLastItemTags - itemsPerPage;
  const sortTagsList = (list) => list?.map((item, i) => ({ ...item, i })).filter((item) => (availableTagSearchTerm.trim() !== "" ? item?.name?.toLowerCase()?.includes(availableTagSearchTerm.trim()) : true))
  const PagedAvailableTags = useMemo(() => sortTagsList(availableTags)?.slice(indexOfFirstItemTags, indexOfLastItemTags), [availableTags, currentPages?.availableTags?.currentPage, availableTagSearchTerm])
  useEffect(() => {
    setCurrentPages((prevState) => ({ ...prevState, availableTags: { ...prevState?.availableTags, ...(availableTagSearchTerm ? { currentPage: 1 } : {}), totalPages: Math.ceil(sortTagsList(availableTags).length / itemsPerPage) } }));
  }, [availableTags, availableTagSearchTerm]);

  const indexOfLastItemSelectedItems = currentPages.selectedItems.currentPage * itemsPerPage;
  const indexOfFirstItemSelectedItems = indexOfLastItemSelectedItems - itemsPerPage;
  const sortSelectedList = (list) => list?.map((item, i) => ({ ...item, i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.processName?.toLowerCase()?.includes(assignedSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) || item?.name?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))
  const PagedSelectedItems = useMemo(() => sortSelectedList(selectedItems)?.slice(indexOfFirstItemSelectedItems, indexOfLastItemSelectedItems), [selectedItems, currentPages?.selectedItems?.currentPage, assignedSearchTerm]);
  useEffect(() => {
    setCurrentPages((prevState) => ({ ...prevState, selectedItems: { ...prevState?.selectedItems, ...(selectedItems ? { currentPage: 1 } : {}), totalPages: Math.ceil(sortSelectedList(selectedItems).length / itemsPerPage) } }));
  }, [selectedItems, assignedSearchTerm]);

  useEffect(() => {
    setSelectedItems([...(selectedTags ?? []), ...(selectedProcesses ?? [])]);
    setCurrentPages((prevState) => ({ ...prevState, selectedItems: { ...prevState?.selectedItems, totalPages: Math.ceil(selectedItems.length / itemsPerPage) } }));
  }, [selectedTags, selectedProcesses, availableProcesses, availableTags]);

  /**
 * Moves an item from one list to another list.
 */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === "droppable" && onUnAssignProcess) onUnAssignProcess(removed);

    // You can't move a tag in processes and vice versa
    if ((droppableSource.droppableId === "availableProcessDroppable" || droppableSource.droppableId === "availableTagDroppable")
    && (droppableDestination.droppableId === "availableProcessDroppable" || droppableDestination.droppableId === "availableTagDroppable")) {
      return;
    }

    // If we need to remove from selected, do it in the good list
    if (droppableSource.droppableId === "selectedDroppable") {
      if (isTag(removed) && droppableDestination.droppableId === "availableTagDroppable") {
         setSelectedTags(selectedTags.filter((element) => element.id !== removed.id));
      }
      else if (!isTag(removed) && droppableDestination.droppableId === "availableProcessDroppable") {
         setSelectedProcesses(selectedProcesses.filter((element) => element.id !== removed.id));
      }
    }

    // If we need to add in selected, do it in the good list
    if (droppableDestination.droppableId === "selectedDroppable") {
      if (isTag(removed)) selectedTags.splice(droppableDestination.index, 0, removed);
      else if (!isTag(removed)) selectedProcesses.splice(droppableDestination.index, 0, removed);
    } else {
      destClone.splice(droppableDestination.index, 0, removed);
    }

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    result.availableProcessDroppable && !isTag(removed) && setAvailableProcesses(result.availableProcessDroppable);
    result.availableTagDroppable && isTag(removed) && setAvailableTags(result.availableTagDroppable);
  };

  const OptionWrapper = ({ children }) => (
    <Box
      component="span"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      maxWidth={150}
    >
      {children}
    </Box>
  );

  const getList = (id) => {
    switch (id) {
      case "availableProcessDroppable":
        return availableProcesses;
      case "selectedDroppable":
        return selectedItems;
      case "availableTagDroppable":
        return availableTags;
      default:
        return undefined;
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      // let state = { items };
      if (source.droppableId === "selectedDroppable") {
        const tagsItems = Array.from(selectedTags);
        const processItems = Array.from(selectedProcesses);
        setSelectedTags(tagsItems);
        setSelectedProcesses(processItems);
      } else if (source.droppableId === "availableProcessDroppable") {
        setAvailableProcesses(items);
      } else {
        setAvailableTags(items);
      }
    } else {
      move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
    }
  };
  const isBothProcessAreasEmpty = isEmpty(selectedProcesses) && isEmpty(PagedAvailableProcesses);
  const isBothTagAreasEmpty = isEmpty(selectedTags) && isEmpty(PagedAvailableTags);
  const handleUnassignItems = () => {
    setSelectedTags((prevTags) => removeFromAvailable(prevTags, checkedAssignedItems.filter((item) => isTag(item))));
    setSelectedProcesses((prevProcesses) => removeFromAvailable(prevProcesses, checkedAssignedItems.filter((item) => !isTag(item))));
    setAvailableTags((prevAvailableTags) => [...prevAvailableTags, ...checkedAssignedItems.filter((item) => isTag(item))]);
    setAvailableProcesses((prevAvailableProcesses) => [...prevAvailableProcesses, ...checkedAssignedItems.filter((item) => !isTag(item))]);
    setCheckedAssignedItems([]);
  };
  const handleAssignProcesses = () => {
    setSelectedProcesses((prevSelectedProcesses) => addToSelected(prevSelectedProcesses, checkedProcesses));
    setAvailableProcesses((prevAvailableProcesses) => removeFromAvailable(prevAvailableProcesses, checkedProcesses));
    setCheckedProcesses([]);
  };
  const handleAssignTags = () => {
    setSelectedTags((prevSelectedTags) => addToSelected(prevSelectedTags, checkedTags));
    setAvailableTags((prevAvailableTags) => removeFromAvailable(prevAvailableTags, checkedTags));
    setCheckedTags([]);
  };

  useEffect(() => {
    setCheckedProcesses(checkedProcesses.filter((process) => !PagedSelectedItems.includes(process)));
    setCheckedTags(checkedTags.filter((tag) => !PagedSelectedItems.includes(tag)));
    setCheckedAssignedItems(checkedAssignedItems.filter((item) => !PagedAvailableTags.includes(item) && !PagedAvailableProcesses.includes(item)));
  }, [selectedItems]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.availableProcess")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
            className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processTagSelector.assignedProcessTags")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
            className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("tagSelector.availableTags")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableProcessSearchTerm}
              setSearchTerm={setAvailableProcessSearchTerm}
              placeholder={t("search.available.processes")}
              disabled={isEmpty(availableProcesses)}
              justify="flex-start"
              xsSize={4}
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={(searchTerm) => {
                setAssignedSearchTerm(searchTerm);
              }}
              placeholder={t("search.assigned.processes")}
              disabled={isEmpty(selectedProcesses) && isEmpty(selectedTags)}
              justify="flex-start"
              xsSize={4}
          />
          <SearchField
              searchTerm={availableTagSearchTerm}
              setSearchTerm={setAvailableTagSearchTerm}
              placeholder={t("search.available.tags")}
              disabled={isEmpty(availableTags)}
              justify="flex-start"
              xsSize={4}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container direction="column" className={classes.processContainer}>
          {!isDisabled && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isEqual(availableProcesses, checkedProcesses) && checkedProcesses?.length}
                onChange={(e) => selectAll(e, availableProcesses, setCheckedProcesses)}
                disabled={isEmpty(PagedAvailableProcesses)}
              />
            }
            label={
              <Typography className={classes.checkBoxLabel}>
                {t("select.all")}
              </Typography>
            }
            className={classes.checkboxContainer}
          />)}
          <Card className={classes.card}>
            <Droppable droppableId="availableProcessDroppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothProcessAreasEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(PagedAvailableProcesses)
                          ? <EmptyDropSection />
                  : PagedAvailableProcesses?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          elevation={0}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              isTag(item)
                          )}
                          >
                            {!isDisabled && (
                            <Checkbox
                              checked={checkedProcesses?.some((elt) => elt?.id === item?.id)}
                              onChange={(e) => selectItem(e, item, checkedProcesses, setCheckedProcesses)}
                            />)}
                            <InformationIcon
                                titleContent={item?.processName || item?.processDescription?.processDisplayName}
                                originalContent={(
                                  <OptionWrapper>
                                    {item?.processName || item?.processDescription?.processDisplayName}
                                  </OptionWrapper>
                                )}
                            />
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {currentPages?.availableProcesses && (
            <Pagination listkey="availableProcesses" currentPages={currentPages} setCurrentPages={setCurrentPages} />
            )}
          </Card>
          {!isDisabled && (
          <CustomButton
            view="outlinedPrimary"
            onClick={handleAssignProcesses}
            disabled={isEmpty(checkedProcesses)}
            className={classes.assigningBtns}
            fullWidth
          >
            {t("assign")}
          </CustomButton>)}
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item className={classes.processContainer}>
          {!isDisabled && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isEqual(selectedItems, checkedAssignedItems) && checkedAssignedItems?.length}
                onChange={(e) => selectAll(e, selectedItems, setCheckedAssignedItems)}
                disabled={isEmpty(PagedSelectedItems)}
              />
            }
            label={
              <Typography className={classes.checkBoxLabel}>
                {t("select.all")}
              </Typography>
            }
            className={classes.checkboxContainer}
          />)}
          <Card className={classes.card}>
            <Droppable droppableId="selectedDroppable" ignoreContainerClipping isCombineEnabled>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={clsx(classes.droppableList, classes.droppableContainer)}
                >
                  {isEmpty(PagedSelectedItems) ? (
                    <EmptyDropSection />
                  )
                      : (
                      PagedSelectedItems?.map((item, i) => (
                        <Draggable
                              key={`selected-process-draggable-${item?.id}`}
                              draggableId={`selected-process-draggable-${item?.id}`}
                              index={i}
                              isDragDisabled={isDisabled}
                          >
                          {(provided, snapshot) => (
                            <Paper elevation={0} key={`selected-paper-draggable-${item?.id}`}>
                              <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                          isTag(item),
                                          true
                                      )}
                                      key={`selected-div-draggable-${item?.id}`}
                                  >
                                {!isDisabled && (
                                <Checkbox
                                  key={`selected-checkbox-draggable-${item?.id}`}
                                  checked={checkedAssignedItems?.some((elt) => elt?.id === item?.id)}
                                  onChange={(e) => selectItem(e, item, checkedAssignedItems, setCheckedAssignedItems)}
                                />)}
                                <InformationIcon
                                    key={`selected-info-draggable-${item?.id}`}
                                    titleContent={isTag(item) ? item.name : (item?.processName || item?.processDescription?.processDisplayName)}
                                    originalContent={(
                                      <OptionWrapper>
                                        {isTag(item) ? item.name : (item?.processName || item?.processDescription?.processDisplayName)}
                                      </OptionWrapper>
                                    )}
                                />
                              </div>
                            </Paper>
                            )}
                        </Draggable>
                      ))
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {currentPages?.selectedItems && (
            <Pagination listkey="selectedItems" currentPages={currentPages} setCurrentPages={setCurrentPages} />
            )}
          </Card>
          {!isDisabled && (
          <CustomButton
                  view="outlinedSecondary"
                  onClick={handleUnassignItems}
                  disabled={isEmpty(checkedAssignedItems)}
                  className={classes.assigningBtns}
                  fullWidth
              >
            {t("unassign")}
          </CustomButton>)}
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item className={classes.processContainer}>
          {!isDisabled && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isEqual(availableTags, checkedTags) && checkedTags?.length}
                onChange={(e) => selectAll(e, availableTags, setCheckedTags)}
                disabled={isEmpty(PagedAvailableTags)}
              />
            }
            label={
              <Typography className={classes.checkBoxLabel}>
                {t("select.all")}
              </Typography>
            }
            className={classes.checkboxContainer}
          />)}
          <Card className={classes.card}>
            <Droppable droppableId="availableTagDroppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothTagAreasEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(PagedAvailableTags)
                          ? <EmptyDropSection />
                  : PagedAvailableTags?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          elevation={0}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              true
                            )}
                          >
                            {!isDisabled && (
                            <Checkbox
                              checked={checkedTags?.some((elt) => elt?.id === item?.id)}
                              onChange={(e) => selectItem(e, item, checkedTags, setCheckedTags)}
                            />)}
                            <InformationIcon
                                titleContent={item.name}
                                originalContent={(
                                  <OptionWrapper>
                                    {item.name}
                                  </OptionWrapper>
                                )}
                            />
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {currentPages?.availableTags && (
            <Pagination listkey="availableTags" currentPages={currentPages} setCurrentPages={setCurrentPages} />
            )}
          </Card>
          {!isDisabled && (
          <CustomButton
                  view="outlinedPrimary"
                  onClick={handleAssignTags}
                  disabled={isEmpty(checkedTags)}
                  className={classes.assigningBtns}
                  fullWidth
              >
            {t("assign")}
          </CustomButton>)}
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default ProcessAndTagSelector;
