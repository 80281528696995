import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import {
  fetchAssignedProcessesByResource,
  fetchProcessesByOrchestrator,
  fetchResource,
  updateResource,
} from "redux/actions/services/index";
import { RESOURCE_BASE_URL } from "util/index";
import ConfirmMessage from "components/ConfirmMessage";
import ProcessSelector from "../../ProcessSelector";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import { isPermitted } from "components/HasPermission";

const tabs = [
  {
    id: "ressource-overview-tab",
    label: "fleet.formSection.fleetInfo",
  },
  {
    id: "ressource-process-tab",
    label: "fleet.formSection.processes",
  },
];

export default function RobotForm(props) {
  const dispatch = useDispatch();
  const {
    setValue, formState: { dirtyFields }, register, getValues, trigger,
  } = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { idResource, mode = "view" } = useParams();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [resourceDataLoading, setResourceDataLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const disableFields = !(mode === "add" || mode === "edit");
  const [resource, setResource] = useState({
    resourceDisplayName: "",
  });

  useEffect(() => {
    setResourceDataLoading(true);
    dispatch(fetchResource(idResource)).then((res) => {
      dispatch(fetchAssignedProcessesByResource(idResource));
      if (res?.data) {
        setResource(res.data);
        setValue("resourceId", res.data.resourceId);
        setValue("processesRunning", res.data.processesRunning);
        setValue("resourceDisplayName", res.data.resourceDisplayName);
        setValue("resourceName", res.data.resourceName);
        setValue("actionsRunning", res.data.actionsRunning);
        setValue("displayStatus", res.data.displayStatus ? t(res.data.displayStatus) : t("Offline"));
        setResourceDataLoading(false);
      }
    });
  }, [idResource, dispatch, setValue]);

  useEffect(() => {
    if (resource?.orchestrator?.id) dispatch(fetchProcessesByOrchestrator(resource.orchestrator.id)).then();
  }, [dispatch, resource]);

  const resourcePreAssignedProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_ROBOT_PROCESSES?.data,
  );

  const processList = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES_BY_ORCHESTRATOR?.data,
  );

  const currentUser = useSelector(
    ({ requests }) => requests.queries.FETCH_CURRENT_USER?.data,
  );

  useEffect(() => {
    if (resourcePreAssignedProcesses && processList) {
      setAssignedProcesses(resourcePreAssignedProcesses?.set || []);
      setAvailableProcesses(
        processList?.filter(
          (process) => !resourcePreAssignedProcesses.set?.find(
            (assigned) => assigned.id === process.id,
          ),
        ),
      );
    }
  }, [resourcePreAssignedProcesses, processList]);

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleCancel = () => {
    if (isEmpty(dirtyFields)) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const redirectToList = () => history.push(RESOURCE_BASE_URL);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    submitUpdate();
    toast.success(t("resource.formControl.succesUpdate"))
  };

  const submitUpdate = () => {
    dispatch(
      updateResource(idResource, assignedProcesses.map((el) => el.id).join(","), getValues("resourceDisplayName")),
    ).then(() => {
      setIsLoading(false);
      setOpenMsgConfirm(false);
      redirectToList();
    });
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const tabsContents = [
    {
      content: (
          resourceDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" alignItems="center" spacing={7}>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceDisplayName")}
                        fullWidth
                        valued={getValues("resourceDisplayName") && !disableFields}
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="resourceDisplayName"
                        label={t("resource.management.formControl.resourceName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceDisplayName"),
                        }}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("actionsRunning")}
                        fullWidth
                        id="actionsRunning"
                        name="actionsRunning"
                        label={`${t(
                            "resource.management.formControl.actionsRunning",
                        )} *`}
                        valued={getValues("actionsRunning") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: typeof getValues("actionsRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceName")}
                        fullWidth
                        valued={getValues("resourceName") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="resourceName"
                        label={t("resource.management.formControl.resourceOriginalName")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceName"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("displayStatus")}
                        fullWidth
                        valued={getValues("displayStatus") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="displayStatus"
                        label={t("resource.management.displayStatus")}
                        InputLabelProps={{
                          shrink: !!getValues("displayStatus"),
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("resourceId")}
                        fullWidth
                        valued={getValues("resourceId") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="resourceId"
                        label={t("resource.management.formControl.resourceId")}
                        InputLabelProps={{
                          shrink: !!getValues("resourceId"),
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("processesRunning")}
                        fullWidth
                        valued={getValues("processesRunning") && !disableFields}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="processesRunning"
                        label={`${t(
                            "resource.management.formControl.processesRunning",
                        )} *`}
                        InputLabelProps={{
                          shrink:
                              typeof getValues("processesRunning") !== "undefined",
                        }}
                    />
                </Grid>
              </Grid>
            </Grid>
          )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("resource.button.cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <Grid item xs={12}>
          <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={mode === "view"}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
            />
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            { idResource ? t("resource.button.update") : t("user.button.save")}
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DialogWithTabs
          open={props?.open}
          entityBaseUrl={RESOURCE_BASE_URL}
          moduleName="ressources"
          tabs={tabs}
          disableFields={disableFields}
          editCondition={isPermitted(currentUser, "Edit Resources")}
          tabsContents={tabsContents}
          idItem={idResource}
          handleChange={handleChange}
          activeStep={activeStep}
          height={disableFields ? 433 : 510}
        />
      {openMsgConfirm && (
      <ConfirmMessage
            message={t("resource.update.confirmMsg")}
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={t("resource.button.update")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={isLoading}
          />
        )}
      {openMsgCancel && (
      <ConfirmMessage
            message={t("resource.update.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("resource.button.discard")}
            buttonCancel={t("resource.button.cancel")}
            isLoading={false}
          />
        )}
    </>
  );
}
