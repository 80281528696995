import React, {
  useState, useEffect, useMemo, useRef, useCallback
} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Add, Settings } from "@material-ui/icons";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import parse from "date-fns/parse";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useTranslation, withTranslation } from "react-i18next";
import {
  getLocales, getRandomColor, isEnvParamEnabled, isSchedulableDisabled
} from "util";
import {
  fetchProcessesExecutionsPlanner,
  fetchProcesses,
  fetchOrchestratorsNamesAndIds,
  fetchPlannerProcessesList,
  updateProcessRiskTime,
  fetchTagsForPlannerFilter,
  fetchPlannerFilterResources,
} from "../../redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import CalendarScheduleForm from "./Components/ScheduleForm";
import RiskManagement from "./Components/RiskManagement";
import CustomToolbar from "./Components/CustomToolBar";
import TreeDaysView from "./Components/TreeDaysView";
import PlannerWarnings from "./Components/PlannerWarnings";
import {
  getDayGridRowStart,
  getSpanNumber,
  getWeekGridRowStart,
} from "./utils";
import {
  setOrchestrator,
  setFullScreen,
  setResource,
} from "../../redux/slices/plannerSlice";
import groupBy from "lodash/groupBy";
import { orderBy } from "lodash";
import clsx from "clsx";
import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  format,
  getDate,
  startOfWeek,
  getDay,
  endOfWeek,
  startOfDay,
  isEqual,
  addMinutes,
  getTime
} from "date-fns";
import PageHeader from "../../components/PageHeader";
import ClearFilter from "../../components/ClearFilter";
import NoDataMenu from "../../components/NoData/NoDataMenu";
import CustomSelectField from "../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../components/CustomButton";
import { HasPermission } from "../../components/HasPermission";
import CustomTooltip from "components/CustomTooltip";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles, { DayStyledCard, StyledCard } from "./style";
import "./style.css";

const dayEventTooltipContent = (event, title, t) => [
  { label: t("Schedule"), value: event?.scheduleName },
    { label: t("task"), value: event?.title },
    { label: t("processes"), value: event?.sessions?.map(({ process }) => process?.processDescription?.processDisplayName) },
    { label: t("resource.management.menu.title"), value: event?.sessions?.map(({ resource }) => resource?.resourceDisplayName) },
    { label: t("Dates"), value: title?.replace(`: ${event?.title}`, "") }
]

const Event = ({ event, handleModalOpen, children }) => {
  const { t } = useTranslation();
  const { title } = children.props;
  const classes = useStyles();
  const ref = useRef(null);

  return (
    <CustomTooltip
      title={(
        <Box width="max-content" px={2} py={1}>
          {dayEventTooltipContent(event, title, t).map(({ label, value }) => (
            <Typography className={classes.taskInfo}>{`${label}: ${value}`}</Typography>
              ))}
        </Box>
      )}
      arrow
      maxWidth="max-content"
    >
      <StyledCard
        ref={ref}
        gridrowstart={getWeekGridRowStart(event.start)}
        span={getSpanNumber(event.start, event.end)}
        backgroundcolor={event.hexColor}
        onClick={() => {
          handleModalOpen(event?.id)
        }}
      >
        <Typography
          className={clsx(classes.vertical, classes.eventTooltip)}
        >
          {event?.title}
        </Typography>
      </StyledCard>
    </CustomTooltip>
  );
};

const EventDay = ({
  event, currentDate, handleModalOpen, children
}) => {
  const { t } = useTranslation();
  const { title } = children.props;
  const classes = useStyles();
  return (
    <CustomTooltip
          title={(
            <Box width="max-content" px={2} py={1}>
              {dayEventTooltipContent(event, title, t).map(({ label, value }) => (
                <Typography className={classes.taskInfo}>{`${label}: ${value}`}</Typography>
              ))}
            </Box>
          )}
          arrow
          maxWidth="max-content"
      >
      <DayStyledCard
      onClick={() => {
        handleModalOpen(event?.id)
      }}
      gridRowStart={getDayGridRowStart(
        format(new Date(currentDate), "EEEE, MMMM d yyyy"),
        event.start,
      )}
      span={getSpanNumber(event.start, event.end)}
      backgroundColor={event.hexColor}
    >
        <Typography className={clsx(classes.vertical, classes.eventTooltip)}>
          {event?.title}
        </Typography>
      </DayStyledCard>
    </CustomTooltip>
  );
};

const CalendarComponent = () => {
  const classes = useStyles();
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: getLocales()
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [plannerData, setPlannerData] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const plannerState = useSelector(({ planner }) => planner);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [SchedulesData, setSchedulesData] = useState(null);
  const [resourceData, setResourceData] = useState(null);
  const [orchestrator, setOrchastrator] = useState(null);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [selectedOrchestrator, setSelectedOrchestrator] = useState("");
  const [selectedResource, setSelectedResource] = useState([]);
  const [openSettings, setSettings] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isPlannerDataLoading, setIsPlannerDataLoading] = useState(false);
  const isResourceConditionActive = selectedResource || !isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || !isEnvParamEnabled("REACT_APP_IS_IVECO_ENV");
  const [currentView, setCurrentView] = useState("week");
  const [openWarnings, setWarnings] = useState(false);
  const [warningsData, setWarningsData] = useState([]);
  const [warningsDataForSelectedDate, setWarningsDataForSelectedDate] = useState([]);
  const [warningsCount, setWarningsCount] = useState([]);
  const [selectedDay, setSelectedDay] = useState();

  const plannerDTO = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESS_PLANNER?.data,
  );

  const processPlannerData = useMemo(() => plannerDTO?.processPlannerDtos, [plannerDTO?.processPlannerDtos]);
  const schedulePlannerData = useMemo(() => plannerDTO?.scheduleDtos, [plannerDTO?.scheduleDtos]);

  const isInFullScreenValue = () => (
    (document.fullscreenElement && document.fullscreenElement !== null)
    || (document.webkitFullscreenElement
      && document.webkitFullscreenElement !== null)
    || (document.mozFullScreenElement
      && document.mozFullScreenElement !== null)
    || (document.msFullscreenElement && document.msFullscreenElement !== null)
  );

  const toggleFullScreen = () => {
    const isInFullScreen = isInFullScreenValue();
    const docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    dispatch(setFullScreen(!plannerState?.isFullScreen));
  };

  const exitHandler = () => {
    if (
      !document.fullscreenElement
      && !document.webkitIsFullScreen
      && !document.mozFullScreen
      && !document.msFullscreenElement
    ) {
      dispatch(setFullScreen(false));
    }
  };

  // adding listenners prefixes for cross-browser codes

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_PROCESSES_LIST?.data,
  );

  const orchestrators = useSelector(
    ({ requests }) => requests.queries.FETCH_ORCHESTRATORS_NAMES_AND_IDS?.data,
  );

  const resources = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_FILTER_ROBOTS?.data,
  );
  const plannerSelectedResource = useSelector(
    ({ planner }) => planner.selectedResource,
  );

  const getFilteredResources = () => {
    if (resourceData) {
      if (selectedOrchestrator) return resourceData?.filter((e) => e?.orchestratorId === selectedOrchestrator?.id)
      return resourceData;
    } return []
  }

  const resourceList = getFilteredResources(resourceData);

  const initFilterFetch = () => {
    dispatch(fetchPlannerProcessesList()).then((res) => {
      dispatch(fetchPlannerFilterResources(res.data.map((e) => e.id)));
    })
    dispatch(fetchOrchestratorsNamesAndIds());
    dispatch(fetchTagsForPlannerFilter()).then((res) => {
      setTags(res.data);
    });
  }
  useEffect(() => {
    initFilterFetch();
  }, []);

  useEffect(() => {
    if (orchestrators) {
      setOrchastrator(
        orchestrators?.filter((e) => !isSchedulableDisabled(e?.orchestratorType))?.map((e) => ({
          id: e?.id,
          title: e?.name,
          sessionNumber: e?.sessionNumber,
          orchestratorType: e?.orchestratorType
        })),
      );
    }
  }, [orchestrators]);

  useEffect(() => {
    if (resources?.length) {
      setResourceData(
        resources?.map((e) => ({
          id: e?.id,
          title: e?.resourceName,
          label: e?.resourceDisplayName,
          orchestratorId: e?.orchestrator?.id
        })),
      );
    }
  }, [resources]);

  useEffect(() => {
    if (resourceList?.length && resourceList.length >= 0 && !selectedResource && (isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || isEnvParamEnabled("REACT_APP_IS_IVECO_ENV"))) setSelectedResource([resourceList?.[0]])
  }, [resourceList, selectedOrchestrator]);

  useEffect(() => {
    if (resourceData?.length === 1) {
      dispatch(setResource(...resourceData));
    }
  }, [resourceData]);
  useEffect(() => {
    setSelectedResource(plannerSelectedResource);
  }, [plannerSelectedResource]);

  useEffect(() => {
    if (schedulePlannerData) {
      setSchedulesData(
        schedulePlannerData
          .map((e, i) => ({
            ...e,
            hexColor: getRandomColor(i),
          })),
      );
    }
  }, [schedulePlannerData]);

  useEffect(() => {
    if (orchestrator && plannerState?.selectedOrchestrator) {
      handleChangeOrchestrator(
        Object.values(orchestrator)?.find(
          ({ id }) => id === plannerState?.selectedOrchestrator.id,
        ),
      );
    } else if (orchestrator) {
      handleChangeOrchestrator(orchestrator[0]);
    }
  }, [orchestrator]);

  const handleChangeOrchestrator = (value) => {
    dispatch(setOrchestrator(value));
    if (value) setSelectedOrchestrator(value);
    if ((isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || isEnvParamEnabled("REACT_APP_IS_IVECO_ENV")) && resourceList?.length && resourceList.length >= 0) setSelectedResource([resourceList?.[0]])
    setSelectedProcesses([]);
  };

  const handleChangeProcess = (newProcess) => {
    if (newProcess) setSelectedProcesses(newProcess);
  };

  const handleChangeResource = (newResource) => {
    if (newResource) {
      dispatch(setResource(newResource));
      setSelectedResource(newResource);
      setSelectedProcesses([]);
    }
  };
  const handleClose = () => {
    setEventId(null)
    setModalOpen(false);
  };
  const handleOpenSettings = () => {
    setSettings(true);
  };

  const handleCloseSettings = () => {
    setSettings(false);
  };

  const handleOpenWarnings = (event, date) => {
    setSelectedDay(date);
    event.stopPropagation();
    setWarnings(true);
  };

  const warningsLogic = () => {
    const warningsCount = [];
    const warningsOfWeekDays = [];
    if (plannerData?.length > 0) {
      for (let j = 0; j < 7; j += 1) {
        const warningsOffAllTheWeek = [];
        const firstDayOfWeek = startOfWeek(new Date(currentDate));
        const iDayOfWeek = addDays(new Date(firstDayOfWeek), j);
        const plannerDataForChosenDate = getPlannerDataForChosenDate(
            plannerData,
            iDayOfWeek,
        );
        if (plannerDataForChosenDate && plannerDataForChosenDate.length > 0) {
          let numberOfWarningForChosenDate = 0;
          const existingReasons = new Set();
          for (let i = 0; i < 48; i += 1) {
            let counter = 0;
            const startTimeInterval = addMinutes(new Date(iDayOfWeek), i * 30);
            const endTimeInterval = addMinutes(new Date(startTimeInterval), 30);
            const warningObj = {
              startDateRange: startTimeInterval,
              endDateRange: endTimeInterval,
              planners: [],
              reason: [],
              processes: [],
            };
            const processes = [];
            const tempStartTimeInterval = getTime(startTimeInterval)
            const tempEndTimeInterval = getTime(endTimeInterval);
            plannerDataForChosenDate.forEach((planner) => {
              const tempPlannerStart = getTime(planner.start)
              const tempPlannerEnd = getTime(planner.end);
              if (tempPlannerStart < tempEndTimeInterval && tempStartTimeInterval < tempPlannerEnd) {
                counter += 1;
                warningObj.planners.push(planner);
                planner?.sessions?.forEach((session) => {
                  if (!(processes?.map(({ id }) => id)?.includes(session?.resource?.id))) { processes.push({
                    resourceName: session?.resource?.resourceName,
                    resourceDisplayName: session?.resource?.resourceDisplayName,
                    processName: session?.process?.processDescription?.processDisplayName,
                    // to prioritize the executions of scheduled processes, we assume that a process without restrictions has the highest priority of 100
                    processPriority: session?.process?.processDescription?.processPriority || 100,
                    processStartDate: planner.start,
                  }); }
                })
              }
            });
            let addWarning = false;
            if (counter > selectedOrchestrator.sessionNumber) {
              addWarning = true;
              const reason = t(
                  "allowedSessionsExceededForAnOrchestrator",
                  { parallelExecutions: counter, OrhcestratorName: selectedOrchestrator.title, allowedSessions: selectedOrchestrator.sessionNumber },
              );
              if (!existingReasons.has(reason)) {
                warningObj.reason.push(reason);
                existingReasons.add(reason);
                numberOfWarningForChosenDate += 1;
              }
            }
            const groupedExecutionsByResource = groupBy(processes, "resourceDisplayName");
            // eslint-disable-next-line no-loop-func
            Object.keys(groupedExecutionsByResource)?.forEach((key) => {
              if (groupedExecutionsByResource[key].length > 1) {
                addWarning = true;
                const resourceConcurrentExecutionsMessage = t(
                    "concurrentExecutionInTheSameResourceMessage",
                    { resourceName: key },
                );
                const orderedExecutionsByPriority = orderBy(
                    groupedExecutionsByResource[key],
                    "processPriority",
                    "asc",
                );
                const processesNames = orderedExecutionsByPriority.map(({ processName }) => processName).join(", ");
                const reason = `${resourceConcurrentExecutionsMessage} ${processesNames}`;
                if (!existingReasons.has(reason)) {
                  warningObj.reason.push(reason);
                  existingReasons.add(reason);
                  numberOfWarningForChosenDate += 1;
                }
              }
            })
            if (addWarning && warningObj?.reason.length > 0) {
              warningObj.processes.push(processes);
              warningsOffAllTheWeek.push(warningObj);
            }
          }
          warningsOfWeekDays.push(warningsOffAllTheWeek);
          warningsCount.push({ iDayOfWeek, numberOfWarningForChosenDate });
        } else {
          warningsOfWeekDays.push(null);
        }
      }
    }
    setWarningsData(warningsOfWeekDays);
    setWarningsCount(warningsCount);
  };

  useEffect(() => {
    warningsLogic();
  }, [plannerData, t]);

  useEffect(() => {
    if (warningsData?.length > 0 && selectedDay) {
      setWarningsDataForSelectedDate(
          warningsData[
              getDay(new Date(selectedDay)) !== -1
                  ? getDay(new Date(selectedDay))
                  : 6
              ],
      );
    }
  }, [selectedDay]);

  const getPlannerDataForChosenDate = (plannerData, selectedDay) => plannerData.filter(
      (planner) => planner.start >= selectedDay
          && planner.end <= addDays(new Date(selectedDay), 1),
  );

  const handleCloseWarnings = () => setWarnings(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const reloadPlannerData = (date, isViewSwitch) => {
    if (isResourceConditionActive) {
      if (!isViewSwitch) setIsPlannerDataLoading(true);
      let processId = [];
      if (selectedProcesses) processId = selectedProcesses?.map((e) => e?.id);
      const resourceIds = selectedResource?.map(({ id }) => id);

    dispatch(
      fetchProcessesExecutionsPlanner(
        format(date || currentDate, "yyyy-MM-dd"),
        selectedOrchestrator?.id,
        processId,
        resourceIds,
        selectedTags
        ),
      ).then(() => setIsPlannerDataLoading(false))
    }
  };
  useEffect(() => {
    if (selectedOrchestrator?.id && isResourceConditionActive) reloadPlannerData();
  }, [selectedOrchestrator]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProcesses && isResourceConditionActive) reloadPlannerData();
  }, [selectedProcesses]);

  useEffect(() => {
    if (selectedResource) reloadPlannerData();
  }, [selectedResource]);

  useEffect(() => {
    if (selectedTags && isResourceConditionActive) reloadPlannerData();
  }, [selectedTags]);

  const refreshData = useCallback(
    (date) => {
      if (Math.abs(differenceInCalendarDays(startOfWeek(new Date(date)), startOfWeek(new Date(currentDate)))) >= 7
        || Math.abs(differenceInCalendarDays(startOfWeek(new Date(date)), endOfWeek(new Date(currentDate)))) > 0) {
        reloadPlannerData(date, true);
        setCurrentDate(date);
      }
    },
    [selectedOrchestrator, selectedResource, selectedProcesses],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processPlannerData) {
      const data = [];
      processPlannerData.forEach((e) => {
        // Split times if start date and end date not in the same day
        if (getDate(new Date(e.start)) !== getDate(new Date(e.end))) {
          const startPlanner = { ...e };
          data.push({
            ...startPlanner,
            start: new Date(e.start),
            end: new Date(endOfDay(new Date(e.start))),
            originalStart: startPlanner.originalStartDate
              ? new Date(startPlanner.originalStartDate)
              : null,
            originalEnd: startPlanner.originalEndDate ? new Date(startPlanner.originalEndDate) : null,
          });
          const endPlanner = { ...e };
          data.push({
            ...endPlanner,
            start: new Date(startOfDay(new Date(e.end))),
            end: new Date(e.end),
            originalStart: endPlanner.originalStartDate
              ? new Date(endPlanner.originalStartDate)
              : null,
            originalEnd: endPlanner.originalEndDate ? new Date(endPlanner.originalEndDate) : null,
          });
        } else {
          data.push({
            ...e,
            start: new Date(e.start),
            end: new Date(e.end),
            originalStart: e.originalStartDate
              ? new Date(e.originalStartDate)
              : null,
            originalEnd: e.originalEndDate ? new Date(e.originalEndDate) : null,
          });
        }
      });

      const displayedData = SchedulesData
        ? data?.map((e) => ({
          ...e,
          hexColor: Object.values(SchedulesData)
            .filter((f) => f?.scheduleName === e?.scheduleName)
            .map((color) => color.hexColor),
        }))
        : [];
      setPlannerData(displayedData);
    }
  }, [processPlannerData, SchedulesData]);

  const getFilteredProcesses = (processesData) => {
    const tempProcesses = processesData ?? [];
    if (!selectedOrchestrator) return [];
    return tempProcesses?.filter((e) => {
      if (!selectedResource || selectedResource.length === 0)
      {
        return e.orchestratorId === selectedOrchestrator?.id
      }
      const processResources = e?.resources?.map(({ id }) => id);
      return e.orchestratorId === selectedOrchestrator?.id
          && selectedResource?.filter((resource) => processResources.includes(resource.id)).length > 0;
    });
  }

  const handleClear = () => {
    setSelectedProcesses([]);
    setSelectedResource([]);
    setSelectedTags([]);
  };
  const customHeader = ({ date, culture, localizer }) => (
    <span className={classes.schedulerTitles}>
      {`${t(localizer.format(date, "EEEE", culture))} 
         ${localizer.format(date, "dd")} 
       `}
      {
          warningsCount && warningsCount.length > 0 && warningsCount.map((warning) => (
              isEqual(warning?.iDayOfWeek, date)
              && warning?.numberOfWarningForChosenDate > 0
              && (
              <IconButton onClick={(event) => handleOpenWarnings(event, date)}>
                <Badge
                        badgeContent={
                          warning?.numberOfWarningForChosenDate
                        }
                        classes={{ badge: classes.alertWarning }}
                    >
                  <ReportProblemOutlinedIcon fontSize="small" />
                </Badge>
              </IconButton>
              )
          ))
      }
    </span>
  );
  useEffect(() => {
    dispatch(fetchTagsForPlannerFilter()).then((res) => {
      setTags(res.data);
    });
  }, [])
  const handleChangeTagsFilter = (event) => {
    setSelectedTags(event?.map(({ id }) => id))
  };

  const handleModalOpen = (editId) => {
    setEventId(editId)
    setModalOpen(true)
  }

  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item>
        <PageHeader title="Planner" />
      </Grid>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={2}>
          <CustomSelectField
            options={orchestrator || []}
            optionLabel="title"
            value={selectedOrchestrator}
            onChange={(event) => handleChangeOrchestrator(event.target.value)}
            label={t("orchestrator")}
            formControlClassName={classes.filtersFormControl}
            additionalMenuNode={(!orchestrator || orchestrator.length === 0) && (
              <Box paddingX={1}><NoDataMenu message={t("no.orchestrator.message")} /></Box>
            )}
            isCustom
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
              multiple
              options={resourceList ?? []}
              optionLabel="label"
              value={selectedResource}
              noOptionsNode={<NoDataMenu message={t("no.resource.message")} />}
              onChange={(event) => handleChangeResource(event)}
              label={t("restriction.form.resources")}
              translateLabel={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
              multiple
              options={tags || []}
              optionLabel="name"
              value={tags?.filter(({ id }) => selectedTags.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
              onChange={(event) => handleChangeTagsFilter(event)}
              label={t("Tags")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
              multiple
              options={processes?.length ? getFilteredProcesses(processes) : []}
              optionLabel="processDisplayName"
              value={selectedProcesses}
              noOptionsNode={<NoDataMenu message={t("no.process.planned")} />}
              onChange={handleChangeProcess}
              label={t("process")}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4} justify="space-between">
          <Grid
            container
            item
            xs={2}
            alignItems="flex-end"
            className={classes.clearFilterContainer}
          >
            <ClearFilter
                clearFilter={handleClear}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            alignItems="flex-end"
            justify="flex-end"
          >
            <HasPermission name="Risk management">
              <CustomButton
                color="secondary"
                className={classes.btnBasicMargin}
                title={t("Risk management")}
                onClick={handleOpenSettings}
              >
                <Settings />
              </CustomButton>
            </HasPermission>
            <CustomButton
              view="primary"
              className={classes.buttonAddScheduler}
              startIcon={<Add />}
              onClick={handleOpen}
            >
              {t("create.scheduler")}
            </CustomButton>
            <CustomButton
              color="secondary"
              className={classes.btnBasicMargin}
              title={
                plannerState?.isFullScreen
                  ? t("planner.fullScreenExit")
                  : t("planner.fullScreen")
              }
              onClick={toggleFullScreen}
            >
              {plannerState?.isFullScreen ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {isPlannerDataLoading ? (
          <CircularLoader height={450} />
        )
          : (
            <Paper className={classes.calendarContainer}>
              <Calendar
                className={
                  plannerState?.isFullScreen
                    ? classes.calendarFullScreen
                    : classes.calendar
                }
                localizer={localizer}
                components={{
                  toolbar: (props) => <CustomToolbar {...props} handleOpenWarnings={handleOpenWarnings} warningsCount={warningsCount} />,
                  header: (props) => customHeader(props),
                  day: {
                    eventWrapper: (props) => EventDay({ ...props, currentDate, handleModalOpen }),
                  },
                  work_week: {
                    eventWrapper: (props) => Event({ ...props, handleModalOpen }),
                  },
                  week: {
                    eventWrapper: (props) => Event({ ...props, handleModalOpen }),
                  },
                }}
                events={plannerData}
                defaultView={currentView}
                defaultDate={new Date()}
                startAccessor="start"
                endAccessor="end"
                selectable
                views={{
                  day: true,
                  week: true,
                  work_week: TreeDaysView,
                }}
                step={15}
                onNavigate={(date) => refreshData(date)}
                onView={(view) => setCurrentView(view)}
              />
            </Paper>
          )}
      </Grid>
      {isModalOpen
        && <CalendarScheduleForm
          scheduleId={eventId}
          editMode={!!eventId}
          openStart={isModalOpen}
          handleClose={handleClose}
          setModalOpen={setModalOpen}
          reloadPlannerData={reloadPlannerData}
        />}
      <RiskManagement
        t={t}
        processes={SchedulesData}
        openStart={openSettings}
        handleClose={handleCloseSettings}
        dispatch={dispatch}
        updateProcessRiskTime={updateProcessRiskTime}
        reloadPlannerData={reloadPlannerData}
        fetchProcesses={fetchProcesses}
      />
      {selectedDay && (
      <PlannerWarnings
              openStart={openWarnings}
              handleClose={handleCloseWarnings}
              warnings={warningsDataForSelectedDate}
              selectedDay={selectedDay}
              orchestrator={selectedOrchestrator}
          />
      )}
    </Grid>
  );
};

export default withTranslation()(CalendarComponent);
