import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { get, debounce, isEmpty } from "lodash";
import { subDays, isAfter } from "date-fns";
import { format } from "date-fns-tz";
import { useHistory, useLocation } from "react-router-dom";
import {
  exceptionCurrentModule,
  formatDatePickerByLanguage,
  handleDisplayedLabel,
  isFleetAdministrator,
  STATUS,
  selectDurationValue,
} from "util";
import { initFilter } from "../../../redux/slices/exceptionFilter";

import ClearFilter from "../../../components/ClearFilter";
import NoDataMenu from "../../../components/NoData/NoDataMenu";
import i18n from "../../../i18n";
import DateFilter from "../../Services/components/DateFilter";
import {
  fetchFleetsForFilter,
} from "../../../redux/actions/services";
import CustomSelectField from "../../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../../components/FormFields/CustomAutoComplete";
import ShowMoreFilters from "../../../components/ShowMoreFilters";
import CustomButton from "../../../components/CustomButton";
import { ReactComponent as GraphIcon } from "../../../assets/common/graph.svg";
import { ReactComponent as AssigmentIcon } from "../../../assets/common/assigment.svg";
import ExportButton from "../../../components/ExportButton";
import { EXPORT_LIMIT } from "util/constants";
import VisibilityIconFilter from "components/VisibilityIconFilter";
import { isPermitted } from "components/HasPermission";
import RulesList from "pages/ExceptionsPage/AutoExceptionAssignment/RulesList";
import PageHeader from "components/PageHeader";
import { SearchFilter } from "components/Filter";
import useStyles from "../style";

const dateFormat = "yyyy/MM/dd HH:mm";
const TagFilter = ({
  tags, filterExceptionList, currModule, handleChangeTagsFilter
}) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={2}>
      <FormControl fullWidth>
        <CustomAutoComplete
          multiple
          options={tags || []}
          optionLabel="name"
          value={tags?.filter(({ id }) => filterExceptionList[currModule].tags.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
          onChange={handleChangeTagsFilter}
          label={t("Tags")}
        />
      </FormControl>
    </Grid>
  )
}
export default function Filter(props) {
  const {
    processes = [],
    exceptionsType,
    exceptions,
    rowCount,
    processName,
    exportExceptionXLSX,
    setExportLoading,
    exportLoading,
    setShowAnalyticsContent,
    showAnalyticsContent,
    sortColumns,
    handleChangeShowHidden,
    showHidden,
    isItemList,
    currModule,
    exceptionWorkFlowStatus = [],
    assigners,
    setOriginalItemId,
    setExportConfirmationOpen,
    totalCount = 0,
    selectedExceptionWorkFlowStatusFilter,
    setSelectedExceptionWorkFlowStatusFilter,
    tags,
    pageTitle,
    filteredItemsCount,
  } = props;
  const classes = useStyles();
  const unassignedItems = { userId: -2, firstname: "Unassigned", lastname: "" };
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [tempExceptionType, setTempExceptionType] = React.useState([]);
  const [tempExceptions, setTempExceptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState(null);
  const [fromSelectedDate, setFromDate] = React.useState(
    format(subDays(new Date(), 1), dateFormat),
  );
  const [toSelectedDate, setToDate] = React.useState(
    format(new Date(), dateFormat),
  );
  const [userFleet, setUserFleet] = useState();
  const [exceptionWorkFlowStatusFilter, setExceptionWorkFlowStatusFilter] = React.useState([]);
  const [selectedExceptionAssignersFilter, setSelectedExceptionAssignersFilter] = React.useState([]);
  const [exceptionAssignersFilter, setExceptionAssignersFilter] = React.useState([]);
  const [showCustomDate, setshowCustomDate] = React.useState(false);
  const defaultSortColumn = sortColumns[2];

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );
  const [showAll, setShowAll] = React.useState(false);
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [futureEndDate, setFutureEndDate] = useState(false)
  const [futureStartDate, setFutureStartDate] = useState(false)
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const [openRuleDialog, setOpenRuleDialog] = React.useState(false);
  const handleShowMore = () => {
    setShowAll(!showAll);
  };
  React.useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  useEffect(() => {
    if (
      filterExceptionList[currModule].exceptionsSelectedDurationValue === "CUSTOM"
    ) {
      setshowCustomDate(true);
    } else setshowCustomDate(false);
  }, [processes.map((p) => p.id).join(), filterExceptionList[currModule]]);

  useEffect(() => {
    setExceptionWorkFlowStatusFilter(exceptionWorkFlowStatus);
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(
        filterExceptionList[currModule]?.workflowStatus,
      );
    }
    setSelectedExceptionAssignersFilter(
      filterExceptionList[currModule]?.usersInCharge,
    )
  }, [exceptionWorkFlowStatus.join()]);

  useEffect(() => {
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(
        filterExceptionList[currModule]?.workflowStatus,
      );
    }
  }, []);

  useEffect(() => {
    const onlyReadyIsSelected = (selectedExceptionWorkFlowStatusFilter && selectedExceptionWorkFlowStatusFilter.length === 1) && (selectedExceptionWorkFlowStatusFilter.includes(STATUS.READY));
    if (onlyReadyIsSelected) {
      setExceptionAssignersFilter([
        unassignedItems,
      ]);
    } else if (assigners) {
      setExceptionAssignersFilter([
        unassignedItems,
        ...assigners,
      ]);
    }

    const currentUserArray = assigners?.filter((element) => element.userId.toString() === currentUser.id);
    if (props.currentUserSet) {
      handleChangeExceptionAssigners(currentUserArray);
    }
  }, [assigners?.map((a) => a.userId).join(), selectedExceptionWorkFlowStatusFilter]);

  useEffect(() => {
    const onlyReadyIsSelected = (selectedExceptionWorkFlowStatusFilter && selectedExceptionWorkFlowStatusFilter.length === 1) && (selectedExceptionWorkFlowStatusFilter.includes(STATUS.READY));
    if (onlyReadyIsSelected) {
      handleChangeExceptionAssigners([unassignedItems]);
    } else handleChangeExceptionAssigners([]);
  }, [exceptionAssignersFilter.map((exp) => exp.id).join()]);

  React.useEffect(() => {
    setTempExceptionType(
      exceptionsType?.map((e, i) => ({ id: i, desc: e }))
    );
  }, [exceptionsType]);

  React.useEffect(() => {
    setTempExceptions(
      exceptions
    );
  }, [exceptions]);
  const handleChangeProcess = (value) => {
    const processIds = value?.map(({ id }) => id);
    props.handleChangeProcess(processIds);
  };

  const handleChangeFleet = (value) => {
    clearFilter();
    const fleetIds = value?.map(({ id }) => id);
    props.handleChangeFleet(fleetIds);
  };

  const handleChangeProcessType = (value) => {
    props.handleChangeExceptionType(value);
  };

  const handleChangeException = (value) => {
    props.handleChangeException(value);
  };

  const handleChangeExceptionWorkflowStatus = (event) => {
    const value = event?.target?.value || event;
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter(value);
    }
    props.handleChangeExceptionWorkflowStatus(value);
  };

  const handleChangeExceptionAssigners = (value) => {
    setSelectedExceptionAssignersFilter(value);
    if (props.currentUserSet && !isEmpty(value)) props.resetCurrentUserFilter();
    if (props.handleChangeExceptionAssigners) props.handleChangeExceptionAssigners(value);
  };
  React.useEffect(() => {
    if (currentUser && isFleetAdministrator(currentUser)) setIsAdmin(true);
  }, [currentUser]);

  const onSelectDurationValue = (value) => {
    selectDurationValue(
      value,
      setToDate,
      setshowCustomDate,
      setFromDate,
      props.handleChangeDates,
      dateFormat,
    );
  };
  const onFromDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureStartDate(true)
    } else if (format(date, dateFormat) > toSelectedDate) {
      setInvalidStartDate(true)
    } else {
      setInvalidStartDate(false)
      setFutureStartDate(false)
      setFromDate(date);
      props.handleChangeDates(
        date,
        toSelectedDate,
        filterExceptionList[currModule]?.exceptionsSelectedDurationValue,
      );
    }
  };
  const onToDateChange = (date) => {
    const currentDate = new Date();
    if (isAfter(date, currentDate)) {
      setFutureEndDate(true)
    } else if (format(date, dateFormat) < fromSelectedDate) {
      setInvalidEndDate(true)
    } else {
      setFutureEndDate(false)
      setInvalidEndDate(false)
      setInvalidStartDate(false)
      setToDate(date);
      props.handleChangeDates(
        fromSelectedDate,
        date,
        filterExceptionList[currModule]?.exceptionsSelectedDurationValue,
      );
    }
  };

  const handleShowAnalytics = () => {
    setShowAnalyticsContent(!showAnalyticsContent);
  };

  const handleOnClickExport = () => {
    localStorage.setItem("lgn", i18n.language);
    if (isItemList) {
      setExportConfirmationOpen(true)
    } else {
      setExportLoading(true);
      exportExceptionXLSX(
        processName,
        filterExceptionList[currModule]?.exceptionType,
        filterExceptionList[currModule]?.exception,
      );
    }
  };

  const handleFilterValueChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value, filterExceptionList);
  };
  const debouncer = React.useCallback(
    debounce((nextValue, currFilter) => {
      props.handleChangeSearchText(nextValue, currFilter);
    }, 500),
    [currModule],
  );

  useEffect(() => {
    setSearchText(filterExceptionList[currModule].searchText);
  }, [currModule]);

  useEffect(() => {
    setSearchText(filterExceptionList[currModule].searchText);
  }, []);
  const clearFilter = () => {
    props.currentUserSet && props.resetCurrentUserFilter();
    dispatch(initFilter());
    setSearchText("");
    setshowCustomDate(false);
    props.handleChangeFleet([]);
    if (setSelectedExceptionWorkFlowStatusFilter) {
      setSelectedExceptionWorkFlowStatusFilter([]);
    }
    setSelectedExceptionAssignersFilter([]);
    if (props.handleChangeExceptionWorkflowStatus) props.handleChangeExceptionWorkflowStatus([]);
    if (setOriginalItemId) setOriginalItemId("");

    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("processExecution")) {
      queryParams.delete("processExecution");
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleetsForFilter()).then((res) => {
        setUserFleet(res.data);
      });
    }
  }, [isInstanceOwner])

  const handleChangeTagsFilter = (value) => {
    props.handleChangeTag(value.map(({ id }) => id))
  };

  return (
    <>
      <Grid container item>
        <Grid container item justify="space-between" alignItems="center">
          <PageHeader
          title={pageTitle}
          filteredCount={filteredItemsCount}
      />
          {rowCount > 0 && (
          <Grid container item xs={2} spacing={1} justify="flex-end">
            {isPermitted(currentUser, "Setup Automatic Assignment") && (
            <Grid item>
              <CustomButton
                    view="secondaryVariant"
                    onClick={() => setOpenRuleDialog(true)}
                    iconBtn
                >
                <Tooltip title={t("Assignement Rules")}>
                  <AssigmentIcon />
                </Tooltip>
              </CustomButton>
            </Grid>
          )}
            <Grid item>
              <CustomButton
                  view="secondary"
                  onClick={() => handleShowAnalytics()}
                  iconBtn
              >
                <Tooltip title={t("filter.graph")}>
                  <GraphIcon />
                </Tooltip>
              </CustomButton>
            </Grid>
            <Grid item>
              <ExportButton
                  loading={exportLoading}
                  onClick={() => handleOnClickExport()}
                  withWarning={currModule === exceptionCurrentModule.ITEMS && totalCount > EXPORT_LIMIT}
                  warningMessage="export.limit"
              />
            </Grid>
          </Grid>)}
        </Grid>
        <Grid container item alignItems="flex-end" spacing={2}>
          <Grid item xs={2}>
            <SearchFilter
              callback={handleFilterValueChange}
              value={searchText}
          />
          </Grid>
          <TagFilter
            handleChangeTagsFilter={handleChangeTagsFilter}
            classes={classes}
            tags={tags}
            filterExceptionList={filterExceptionList}
            currModule={currModule}
        />
          <Grid item xs={2}>
            <FormControl fullWidth>
              <CustomAutoComplete
                multiple
                options={processes || []}
                optionLabel="processName"
                value={processes?.filter(({ id }) => filterExceptionList[currModule].process.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
                onChange={handleChangeProcess}
                label={t("processes")}
            />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <CustomAutoComplete
                multiple
                options={tempExceptions || []}
                optionLabel="exceptionReason"
                value={tempExceptions?.filter(({ id }) => filterExceptionList[currModule].exception.map(({ id }) => id).includes(id)) ?? []}
                noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                onChange={handleChangeException}
                label={t("Exception Reason")}
            />
            </FormControl>
          </Grid>
          {currModule === exceptionCurrentModule.ITEMS && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <CustomAutoComplete
                multiple
                options={exceptionWorkFlowStatusFilter || []}
                value={exceptionWorkFlowStatusFilter?.filter((fd) => selectedExceptionWorkFlowStatusFilter.includes(fd))}
                onChange={(event) => handleChangeExceptionWorkflowStatus(event)}
                label={t("Exception Workflow Status")}
              />
            </FormControl>

          </Grid>
        )}
          {showAll && (
          <>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <CustomAutoComplete
                    multiple
                    options={tempExceptionType || []}
                    optionLabel="desc"
                    value={tempExceptionType?.filter(({ id }) => filterExceptionList[currModule].exceptionType.map(({ id }) => id).includes(id)) ?? []}
                    noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                    onChange={handleChangeProcessType}
                    label={t("Exception Type")}
                />
              </FormControl>
            </Grid>
            {isInstanceOwner && (
              <Grid item xs={2} className={classes.groupContainer}>
                <FormControl fullWidth>
                  <CustomAutoComplete
                      multiple
                      options={userFleet || []}
                      optionLabel="companyName"
                      value={userFleet?.filter(({ id }) => filterExceptionList[currModule].fleet.includes(id))}
                      noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                      onChange={handleChangeFleet}
                      label={t("groups")}
                  />
                </FormControl>
              </Grid>
            )}
            {currModule === exceptionCurrentModule.ITEMS && (
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <CustomAutoComplete
                    multiple
                    options={exceptionAssignersFilter || []}
                    optionUuid="userId"
                    optionLabel={["firstname", "lastname"]}
                    value={exceptionAssignersFilter?.filter((fd) => selectedExceptionAssignersFilter.includes(fd))}
                    noOptionsNode={<NoDataMenu message={t("no.exception.message")} />}
                    onChange={handleChangeExceptionAssigners}
                    label={t("Exception Assignee")}
                  />
                </FormControl>
              </Grid>
            )}
            <DateFilter
              onFromDateChange={onFromDateChange}
              fromDate={filterExceptionList[currModule]?.exceptionsFromDate}
              onToDateChange={onToDateChange}
              toDate={filterExceptionList[currModule]?.exceptionsToDate}
              dateFormat={formatDatePickerByLanguage()}
              classes={classes}
              selectedDuration={
                filterExceptionList[currModule]?.exceptionsSelectedDurationValue
              }
              onSelectDurationValue={onSelectDurationValue}
              showCustomDate={showCustomDate}
              isAdmin={isAdmin}
              type="exception"
              invalidEndDate={invalidEndDate}
              invalidStartDate={invalidStartDate}
              futureEndDate={futureEndDate}
              futureStartDate={futureStartDate}
              isCustom
              xs={2}
            />
            <Grid item xs={2}>
              <CustomSelectField
                options={sortColumns}
                optionLabel="label"
                value={sortColumns?.find(({ label }) => label === filterExceptionList[currModule]?.order?.label)}
                onChange={(e) => props.handleRequestSort(
                  e.target.value ? e.target.value : defaultSortColumn
                )}
                variant="standard"
                label={t("Sort By")}
                customOptionLabel={(option) => handleDisplayedLabel(t(option?.label))}
                isCustom
              />
            </Grid>
          </>
        )}
          {!isItemList && (
          <Grid item xs={1}>
            <VisibilityIconFilter
              label={t("exceptions.show.hidden")}
              showAll={showHidden}
              handleShowAll={handleChangeShowHidden}
          />
          </Grid>)}
          <Grid container item xs={2} justify="flex-end" className={classes.autoMarginLeft}>
            <Grid item>
              <ShowMoreFilters handleShowMore={handleShowMore} showAll={showAll} />
            </Grid>
            <Grid item>
              <ClearFilter
              clearFilter={clearFilter}
            />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openRuleDialog && (
      <RulesList open={openRuleDialog} handleDialogClose={() => setOpenRuleDialog(false)} itemData={[]} />
    )}
    </>
  );
}
