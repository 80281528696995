import { makeStyles, fade, darken } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
    cursor: "pointer",
    background: "white !important",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  customDate: {
    display: "flex",
    alignItems: "start",
    marginLeft: "10px",
  },
  rootGlobal: {
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(-3),
  },
  content: {
    display: "flex",
    width: "100%",
    padding: "0",
    justifyContent: "space-between",
    alignItems: "center",
  },
  action: {
    display: "flex",
    alignItems: "center",
    cursor: "default",
    height: "100%",
  },
  contentFilter: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  actionHead: {
    justifyContent: "right",
    paddingLeft: theme.spacing(4.5),
  },
  select: {
    padding: "12px",
  },

  downloadIcon: {
    color: theme.palette.success.main,
  },

  tableRow: {
    background: theme.palette.common.white,
    cursor: "pointer",
    padding: "20px 0px",
  },
  tableCell: {
    fontWeight: "400",
    paddingLeft: theme.spacing(3),
  },
  tableHeadLabel: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(16),
    padding: "10px 0",
    color: "gray",
  },

  tableCellHead: {
    borderBottom: "none",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: fade(theme.palette.primary.main, 0.5),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  card: {
    background: "transparent",
    width: "100%",
    height: "100%",
    padding: theme.spacing(2, 4),
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  center: {
    textAlign: "center",
    alignItems: "center",
  },
  right: {
    textAlign: "right",
    alignItems: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 700,
  },
  deleteIcon: {
    color: theme.custom.color.color4,
  },
  autoCompleteRight: {
    width: "85%",
    marginLeft: "52%",
  },
  tableCellAction: {
    fontWeight: "400",
  },
  image: {
    width: 40,
    height: 40,
  },
  actions: {
    marginRight: theme.spacing(2),
  },
  processCard: {
    paddingLeft: theme.spacing(1),
  },
  exportButton: {
    marginLeft: theme.spacing(1),
  },
  field: {
    fontWeight: 700,
    wordWrap: "break-word",
  },
  value: {
    wordWrap: "break-word",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    marginTop: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(2, 0),
  },
  clearBtn: {
    backgroundColor: theme.custom.color.color4,
    marginLeft: theme.spacing(0.5),
    color: "white",
    "&:hover": {
      background: darken(theme.custom.color.color4, 0.2),
    },
  },
  input: {
    fontSize: "1rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  dateInput: {
    marginTop: 0,
  },
  actionButtons: {
    margin: "auto",
    marginRight: 0,
    maxHeight: 37,
  },
  visibilityIcon: {
    color: theme.custom.color.icons.visibility,
  },
  groupContainer: {
    paddingRight: "16px !important",
  },
  itemDataName: {
    fontWeight: 700,
    fontSize: "0.8rem",
    color: theme.palette.primary.main
  },
  itemDataValue: {
    fontWeight: 600,
    fontSize: "0.8rem",
    color: theme.palette.primary.main
  },
  autoMarginLeft: {
    marginLeft: "auto"
  }
}));
