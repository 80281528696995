import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CustomDialog from "components/CustomDialog";
import Tooltip from "@material-ui/core/Tooltip";
import ExportButton from "../../../../components/ExportButton";
import CustomCloseButton from "../CustomCloseButton";
import {
  exportExecutionsXLSX,
  fetchExportedFiles,
  fetchProcessExecutionByProcess,
  fetchAllStatus,
} from "../../../../redux/actions/services";
import EnhancedTableHead from "../../../../components/DataTable/EnhancedTableHead";
import { EnhancedTableToolbar } from "../../../../components/DataTable/EnhancedTableToolbar";
import StatusButton from "../../../../components/StatusButton";
import {
  exceptionCurrentModule,
  formatDateByLanguage,
  formtNumberByLanguage,
  secondsToTime,
} from "util";
import DataNotFound from "../../../../components/DataNotFound";
import processExecutionNotFoundIcon from "../../../../assets/Process_Overview.svg";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import { updateFilter as updateItemsFilter } from "../../../../redux/slices/workqueueitemSlice";
import { updateFilter as updateExceptionsFilter } from "../../../../redux/slices/exceptionFilter";
import { toast } from "react-toastify";
import CustomPagination from "../CustomPagination";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import { exceptionsPathLabel } from "util/helpers/preference.helper";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getRate(count, total) {
  if (!total || !count) return 0;
  return Math.round((count / total) * 10000 + Number.EPSILON) / 100;
}

const HistoricalMoreInfo = ({
 details, setDetails, classes, t
}) => {
  const handleClose = () => {
    setDetails({ ...details, open: false });
  };

  const historyFields = [{ title: "Exception Type", content: "row.exceptiontype" }, { title: "Exception Reason", content: "row.exceptionmessage" }]

  return (
    <CustomDialog
      open={details.open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.historyDialog}
      title={
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.historyDialogTitle}
        >
          <Grid item>{t("Exception")}</Grid>
          <Grid item>
            <CustomCloseButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid container className={classes.historyDialogContent} spacing={1}>
        {historyFields?.map((field, index) => (
          <Grid container item xs={12} justify="space-between" id={index}>
            <Grid item xs={4} className={classes.historyDialogRowTitle}>
              {t(field?.title)}
            </Grid>
            <Grid item xs={8} id="alert-dialog-slide-description" className={classes.historyDialogRowContent}>
              {get(details, field?.content, "---")}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </CustomDialog>
  );
};

const CustomInput = withStyles((theme) => ({
  input: {
    fontWeight: "700"
  },
  root: {
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `3px solid ${theme.custom.color.staticInput}`,
    },
  },
  underline: {
      position: "relative",
      backgroundColor: "transparent",
      fontSize: 14,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:before": {
        borderBottom: `3px solid ${theme.custom.color.staticInput}`,
      },
      "&:hover::before": {
        borderBottom: theme.custom.color.staticInput,
      },
  },
}))(Input);

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    padding: "10px",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`
  },
}))(TableCell);

function HistoricalInfo(props) {
  const { classes, process } = props;
  const history = useHistory();
  const [loaded, setLoaded] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowData, setRowData] = React.useState([]);
  const [lenghtData, setLenghtData] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("executionStartTime");
  const [showFilter, setShowFilter] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [openDetails, setOpenDetails] = React.useState({
    open: false,
    row: {},
  });
  const [exportLoading, setExportLoading] = React.useState(false);
  const { t } = useTranslation();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const workqueueitemFilter = useSelector(({ workqueueitem }) => workqueueitem);
  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );

  const dispatch = useDispatch();
  const headCells = [
    { id: "executionStartTime", label: t("Start time"), sortable: true },
    { id: "executionEndTime", label: t("End time"), sortable: true },
    {
      id: "executionDuration",
      label: t("process.history.duration"),
      sortable: true,
    },

    { id: "status", label: t("Status") },
    {
      id: "completedItems",
      label: t("process.history.completedItems"),
      position: "center",
    },
    {
      id: "exceptionItems",
      label: t("process.history.exceptionItems"),
      position: "center",
    },
    {
      id: "resource",
      label: t("process.history.resource"),
      position: "center",
    },
  ];

  const getData = (page, size, sortField, sortOrder, status) => {
    setIsLoaded(false);
    const statusId = status ? status.map((e) => e) : [];
    if (orderBy && order) {
      props
        .fetchProcessExecutionByProcess(
          process.id,
          page,
          size,
          sortField,
          sortOrder,
          statusId,
          get(currentUser, "id")
        )
        .then((result) => {
          setRowData(result?.data?.list);
          setLenghtData(result?.data?.resultsCount);
          setIsLoaded(true);
        });
    }
  };

  useEffect(() => {
    if (!loaded && currentUser) {
      setLoaded(true);
      props.fetchAllStatus([process.id]).then((resultS) => {
        setStatus(resultS.data);
        getData(page, rowsPerPage, orderBy, order, selectedStatus);
      });
    }
  }, [
    loaded,
    page,
    rowsPerPage,
    props,
    process.id,
    getData,
    order,
    orderBy,
    selectedStatus,
    currentUser,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getData(
      page,
      rowsPerPage,
      property,
      isAsc ? "desc" : "asc",
      selectedStatus?.map(({ id }) => id)
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, rowsPerPage, orderBy, order, selectedStatus?.map(({ id }) => id));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getData(0, event.target.value, orderBy, order, selectedStatus?.map(({ id }) => id));
  };

  const editShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleChangeStatus = (val) => {
    setSelectedStatus(val);
    setPage(0);
    getData(0, rowsPerPage, orderBy, order, val.map(({ id }) => id));
  };

  const handleShowExceptionDetails = (row) => {
    setOpenDetails({ open: true, row });
  };

  const onSuccessCountClick = (executionId) => {
    dispatch(
      updateItemsFilter({
        ...workqueueitemFilter,
        process: [process],
      })
    );
    history.push({
      pathname: "/items/completed",
      state: { processExecutionId: [executionId] },
    });
  };
  const onExceptionCountClick = (executionId) => {
    dispatch(
      updateExceptionsFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          process: [process],
        },
      }),
    );
    history.push({
      pathname: `/${exceptionsPathLabel}/exception`,
      state: { processExecutionId: [executionId] },
    });
  };

  const handleExport = () => {
    setExportLoading(true);
    dispatch(
      exportExecutionsXLSX(
        process.id,
        selectedStatus?.map((s) => s.id)
      )
    ).then(() => {
      setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getData(page + 1, rowsPerPage, orderBy, order, selectedStatus?.map(({ id }) => id));
  }
  const handlePrevious = (page) => {
    setPage(page - 1);
    getData(page - 1, rowsPerPage, orderBy, order, selectedStatus?.map(({ id }) => id));
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <EnhancedTableToolbar
          filtred
          onShow={editShowFilter}
          numSelected={0}
          button={
            rowData && (
              <ExportButton
                  loading={exportLoading}
                  onClick={handleExport}
              />
            )
          }
          filterInput={
            <CustomAutoComplete
                multiple
                options={status || []}
                optionLabel="statusCode"
                value={selectedStatus}
                noOptionsNode={<NoDataMenu message={t("no.status.message")} />}
                onChange={handleChangeStatus}
                label={t("status")}
            />
          }
        />

        <TableContainer className={classes.historicalInfoTableContainer}>
          {isLoaded ? (
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              {lenghtData > 0 && rowData && <EnhancedTableHead
                classes={classes}
                numSelected={0}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
                emptyCell
              />}
              <TableBody>
                {lenghtData && rowData ? (
                  stableSort(rowData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const itemsCount = get(row, "completedItemsCount", 0)
                        + get(row, "exceptionItemsCount", 0);
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={`history-table-row-${index}`}
                        >
                          <CustomTableCell align="left">
                            <Typography variant="caption" className={classes.tableCellText}>
                              {row?.executionStartTime
                                ? formatDateByLanguage(row?.executionStartTime)
                                : formatDateByLanguage(row?.launchingTime)}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography variant="caption" className={classes.tableCellText}>
                              {row?.executionEndTime
                                ? formatDateByLanguage(row?.executionEndTime)
                                : "---"}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography variant="caption" className={classes.tableCellText}>
                              {row?.executionDuration
                                ? secondsToTime(row?.executionDuration, t)
                                : "---"}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <StatusButton
                              status={
                                row.processStatus?.statusCode
                                  ? row.processStatus?.statusCode
                                  : row.srStatus
                              }
                              label={
                                row.processStatus?.statusLabel
                                  ? row.processStatus?.statusLabel
                                  : row.srStatus
                              }
                              customClass={classes.fullWidth}
                            />
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <FormControl>
                              <CustomInput
                                id="input-with-icon-adornment"
                                value={`${formtNumberByLanguage(
                                  get(row, "completedItemsCount", "---")
                                )} | (${formtNumberByLanguage(
                                  getRate(
                                    get(row, "completedItemsCount"),
                                    itemsCount
                                  )
                                )}%)`}
                                readOnly
                                className={classes.itemsFinished}
                                inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <AssignmentTurnedInOutlinedIcon className={classes.itemsFinishedIcon} />
                                  </InputAdornment>
                                }
                                onClick={() => onSuccessCountClick(row?.id)}
                              />
                            </FormControl>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <CustomInput
                                id="input-with-icon-adornment"
                                value={`${formtNumberByLanguage(
                                  get(row, "exceptionItemsCount", "---")
                                )} | (${formtNumberByLanguage(
                                  getRate(
                                    get(row, "exceptionItemsCount"),
                                    itemsCount
                                  )
                                )}%)`}
                                readOnly
                                className={classes.itemsFinished}
                                inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <ReportProblemOutlinedIcon className={classes.itemsExceptionIcon} />
                                  </InputAdornment>
                                }
                                onClick={() => onExceptionCountClick(row?.id)}
                              />
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography variant="caption" className={classes.tableCellText}>
                              {row?.startingResource?.resourceDisplayName ?? "---"}
                            </Typography>
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            {(row?.exceptionmessage || row?.exceptiontype) && (
                              <Tooltip title={t("Details")}>
                                <IconButton
                                size="small"
                                  color="primary"
                                  onClick={() => handleShowExceptionDetails(row)}
                                >
                                  <MoreHorizIcon size="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </CustomTableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <div>
                    <DataNotFound
                        padding={50}
                        message={t("no.execution.message")}
                        icon={processExecutionNotFoundIcon}
                      />
                  </div>
                )}
              </TableBody>
            </Table>
          ) : (
            <CircularLoader />
          )}
        </TableContainer>
        {lenghtData > 0 && rowData && <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={lenghtData || 0}
          onChangePage={handleChangePage}
          onNext={handleNext}
          onPrevious={handlePrevious}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
      </Paper>
      <HistoricalMoreInfo
        details={openDetails}
        setDetails={setOpenDetails}
        classes={classes}
        t={t}
      />
    </div>
  );
}
const mapDispatchToProps = {
  fetchProcessExecutionByProcess,
  fetchAllStatus,
};
export default connect(null, mapDispatchToProps)(HistoricalInfo);
