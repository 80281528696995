import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  editStatusResource,
  enableOrDisableResource,
} from "../../../../../redux/actions/services";
import { resourceStatus } from "util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import PermissionManagement from "../../../PermissionsManagement/PermissionsManagement";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import { isPermitted } from "components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";

export default function RobotTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("isActive") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
  });

  const deleteConfirmButton = getFieldData("isActive") === "ACTIVE"
      ? t("tooltip.action.hide")
      : t("tooltip.action.show");
  const displayFields = [
    {
      id: "displayStatus",
      width: 50,
      customContent: <StatusBadge level={getFieldData("displayStatus")} classes={classes} />,
    },
    {
      id: "resourceDisplayName",
      valuePath: "resourceDisplayName",
    },
    {
      id: "orchestratorName",
      valuePath: "orchestrator.name",
    },
    {
      id: "attributeName",
      valuePath: "attributeName",
    },
    {
      id: "displayStatus",
      valuePath: "displayStatus",
      defaultValue: "UNKNOWN",
      translate: true,
    },
    {
      id: "processesRunning",
      valuePath: "processesRunning",
    },
    {
      id: "last30DaysOccupancy",
      valuePath: "last30DaysOccupancy",
      format: (v) => v.toFixed(2),
      isPercentageValue: true
    },
    {
      id: "countAutomations",
      valuePath: "countAutomations",
    },
    {
      id: "countScheduledAutomations",
      valuePath: "countScheduledAutomations",
    },
  ];

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableResource(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const snackBarMessageOfResource = () => {
    const message = row.isActive === resourceStatus.ACTIVE ? t("hidden") : t("unhidden");
    toast.success(t("resource.hidden.success", { status: message }));
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(editStatusResource(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        snackBarMessageOfResource();
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const isResourceActive = row?.isActive === "ACTIVE";
  const deleteMsg = isResourceActive
    ? t("resource.management.hide.confirmMsg")
    : t("resource.management.show.confirmMsg");

  const generateText = (field) => {
    let text;
    if (field.translate) text = t(getFieldData(field?.valuePath) ?? field.defaultValue);
    if (field.format) text = field.format(getFieldData(field.valuePath));
    else text = getFieldData(field?.valuePath)
    if (field.isPercentageValue) text += "%";
    return text;
  }
  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (
          <CustomTableCell
            key={i}
            title={t(field?.title)}
            text={generateText(field)}
            width={field?.width}
          >
            {field.customContent}
          </CustomTableCell>
        ))}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <CustomEditIcon
                    id="admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
            )}
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={
                    isResourceActive
                      ? t("tooltip.action.hide")
                      : t("tooltip.action.show")
                  }
                >
                  <IconButton
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  >
                    {isResourceActive ? (
                      <VisibilityOffIcon
                          className={classes.hideIcon}
                      />
                    ) : (
                      <VisibilityIcon
                          className={classes.showIcon}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <Checkbox
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(parseInt(getFieldData("id"), 10))
                      : list.splice(
                          list.indexOf(parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("resource.management.disable.confirmMsg")
            : t("resource.management.enable.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("resource.management.disable.button.confirm")
            : t("resource.management.enable.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={deleteMsg}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={deleteConfirmButton}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
    </>
  );
}
